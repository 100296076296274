import React from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";

function TurnaroundJewelleryUpdated() {
  const { client, product } = useParams();

  return (
    <>
      {client === "gabrielny" && (
        <div className={styles.container}>
          <model-viewer
            src={`https://advertflair-public.s3.us-west-2.amazonaws.com/client/${client}/output/${product}.glb`}
            ar
            poster={`https://advertflair-public.s3.us-west-2.amazonaws.com/client/${client}/output/${product}.webp`}
            camera-controls
            tone-mapping="neutral"
            shadow-intensity="2"
            exposure="1"
            shadow-softness="1"
            environment-image="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/jewellery_light.hdr"
            style={{ width: "100%", height: "100vh" }}
            touch-action="pan-y"
            autoplay
            field-of-view="30deg"
          ></model-viewer>
        </div>
      )}

      {client != "gabrielny" && (
        <div className={styles.container}>
          <model-viewer
            src={`https://advertflair-public.s3.us-west-2.amazonaws.com/client/${client}/output/${product}.glb`}
            ar
            poster={`https://advertflair-public.s3.us-west-2.amazonaws.com/client/${client}/output/${product}.webp`}
            camera-controls
            tone-mapping="neutral"
            shadow-intensity="2"
            exposure="1"
            shadow-softness="1"
            environment-image="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/jewel-lightmap.hdr"
            style={{ width: "100%", height: "100vh" }}
            touch-action="pan-y"
            autoplay
            field-of-view="30deg"
          ></model-viewer>
        </div>
      )}
    </>
  );
}

export default TurnaroundJewelleryUpdated;
